.bulkbiltyhead tr td {
  font-size: 9px;
}

.watermark::after {
  content: '';
  position: absolute;
  top: 36mm;
  left: 60mm;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-image: url('https://res.cloudinary.com/dhtnk2eku/image/upload/c_thumb,w_200,g_face/v1707464263/h29x4ho1vwby2bweuqb0.png');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.2;
  pointer-events: none;
}
